"use client";
import Button from "@/components/Button";
import { usePathname, useSearchParams } from "next/navigation";
import styles from "./header.module.scss";
import Image from "next/image";
import { Images, LEFT_ARROW_ICON_BLUE } from "@/utils/imageRelativePaths";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useAccount, useSignMessage } from "wagmi";
import WalletCart from "@/components/WalletCart";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setPopUpData, showPopUp } from "@/redux/reducers/PopUpsReducer";
import { login, logout, setReferralCode } from "@/redux/reducers/AuthReducer";
import { getMyDetails, loginUser } from "@/network/auth";
import { AxiosError, sideBarLink, userType } from "@/types/global";
import { type SignMessageErrorType, type UserRejectedRequestError } from "viem";
import { formatNumberWithCommas, getErrorMessage } from "@/utils/helper";
import { toast } from "react-toastify";
import Loading from "@/components/Loading";
import Tooltip from "@/components/Tooltip";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import Dropdown from "@/components/Dropdown";
import { SideBarLinks } from "@/modules/SideBar";
import SVG from "react-inlinesvg";
import { getMyRewards } from "@/network/rewards";
import { setRewards } from "@/redux/reducers/RewardReducer";
import userOpenLink from "@/customHooks/userOpenLink";
import useVerifyChain from "@/customHooks/useVerifyChain";

export default function Header() {
  const pathname = usePathname();
  const router = useRouter();
  const account = useAccount();
  const { open, close } = useWeb3Modal();
  const { openLink } = userOpenLink();
  const verifyChain = useVerifyChain();
  const token = useAppSelector((state) => state.Auth.token);
  const user = useAppSelector((state) => state.Auth.user);
  const isHomePage = pathname === "/";
  const [timestamp, setTimestamp] = useState<number>(0);
  const { signMessage, data, status, failureReason } = useSignMessage();
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const referral_code = searchParams.get("r");
  const siteName = useAppSelector((state) => state.Config.SITE_NAME);
  const siteKey = useAppSelector((state) => state.Config.SITE_KEY);
  const totalPoints = useAppSelector((state) => state.Reward.pointsTotal);
  const referralCode = useAppSelector((state) => state.Auth.referralCode);
  const isCeresSite = siteKey === "ceres";

  // handle referral code
  useEffect(() => {
    if (referral_code) {
      dispatch(setReferralCode(referral_code));
    }
  }, [referral_code]);

  useEffect(() => {
    console.log("status", account);
    handleAccountStatus();
  }, [account.status, referral_code]);
  // get Rewards Data
  useEffect(() => {
    const getData = async () => {
      if (token) {
        const data = await getMyRewards();
        dispatch(setRewards(data));
      }
    };
    getData();
  }, [token]);
  const handleAccountStatus = async () => {
    switch (account.status) {
      case "connected": {
        const currentTimestamp = Date.now();
        setTimestamp(currentTimestamp);

        // if token exists parse token and get all data
        // no need of signing message
        if (token) {
          const user = await getMyDetails();
          if (
            user &&
            user?.address &&
            account?.address &&
            user?.address?.toLowerCase() === account?.address.toLowerCase()
          ) {
            await verifyChain();
            handleLogin({ token, user });
            return;
          }
        }
        await verifyChain();
        const message = signMessage(
          {
            message: `Sign in to ${siteName}: ${currentTimestamp}`,
            // message: `${currentTimestamp}`,
          },
          {
            onSuccess: onSuccess,
          },
        );
        break;
      }
      case "disconnected": {
        // dispatch(logout());
        break;
      }
      default: {
      }
    }
  };
  const onSuccess = (a: string) => {
    console.log("signature", a);
  };
  useEffect(() => {
    if (data) {
      handleLogin();
    }
  }, [data]);
  useEffect(() => {
    if (failureReason) {
      const reason = failureReason as UserRejectedRequestError;
      toast.error(reason?.shortMessage ?? "Request Error", {
        position: "top-right",
      });
    }
    if (status === "error") {
      disconnectWallet();
    }
  }, [status]);

  const disconnectWallet = () => {
    if (account.isConnected && account?.connector?.disconnect) {
      account.connector.disconnect();
      dispatch(logout());
    }
  };
  const handleLogin = async (tokenData?: { token: string; user: userType }) => {
    let loginData;
    if (tokenData) {
      loginData = tokenData;
    } else {
      loginData = await loginUser(
        account.address as string,
        data as string,
        timestamp,
        referralCode || referral_code,
      );
    }
    if (loginData) {
      const { token, user } = loginData;
      dispatch(login({ token, user }));
      if (!user || !user.username) {
        setPopUpData(user);
        dispatch(showPopUp("Profile"));
      }
    }
  };
  const showSideBarItem = (link: sideBarLink) => {
    return !link.site || link.site === siteKey;
  };
  const handleClick = (key: string) => {
    switch (key) {
      case "home": {
        router.push("/");
        break;
      }
      case "tokens": {
        if (account.isConnected) {
          router.push(encodeURI(`/profile/${user?._id}?tab=COIN_CREATED`));
        } else {
          toast.error("Please Connect Wallet");
        }
        break;
      }
      case "rewards": {
        router.push("/rewards");
        break;
      }
      case "create_token": {
        dispatch(showPopUp("CreateCoinPopUp"));
        break;
      }
      case "how_it_works": {
        dispatch(showPopUp("HowItWorks"));
        break;
      }
      case "telegram": {
        openLink(isCeresSite ? "ceres_labs" : "FOMOCLICK", "telegram");
        break;
      }
      case "twitter": {
        openLink(
          isCeresSite
            ? "https://x.com/cereslabs_"
            : "https://x.com/fomodotclick",
          "twitter",
        );
        break;
      }
      case "docs": {
        if (isCeresSite) return;
        openLink("https://fomo-click.gitbook.io/fomo.click");
        break;
      }
      case "fomo_bot": {
        openLink("https://t.me/FOMO_TradingBot");
        break;
      }
      case "trend_bot": {
        openLink("https://t.me/Fomo_Trending");
        break;
      }
      case "buy_bot": {
        openLink("https://t.me/FOMO_freebuybot");
        break;
      }
      default: {
      }
    }
  };
  return (
    <>
      <div
        className={`flex h-[45px] items-center w-full justify-between ${styles.mobileHeaderCont}`}
      >
        <div className={"h-full flex items-center"}>
          <Image
            src={Images.FOMO_CLICK_IMG as string}
            alt={"logo"}
            width={91}
            onClick={() => router.push("/")}
            height={91}
          />
          <Image src={Images.BASE_LOGO} alt={"base"} width={24} height={24} />
        </div>
        <Button
          className={"flex items-center justify-center w-[97px] h-[41px]"}
          onClick={() => dispatch(showPopUp("CreateCoinPopUp"))}
        >
          <Image src={Images.PLUS_CIRCLE} alt={"logo"} width={24} height={24} />
          Token
        </Button>
        {account.status === "connected" ? (
          <WalletCart />
        ) : (
          <Button
            className={"flex items-center justify-center w-[97px] h-[41px]"}
            onClick={() => open()}
          >
            <Image src={Images.WALLET} alt={"logo"} width={24} height={24} />
          </Button>
        )}
        <Dropdown
          className="right-0 w-[150px]"
          parentContainer={
            <div className={"flex w-[18px] flex-col gap-1"}>
              {[1, 2, 3].map((item) => (
                <div key={item} className={"w-full h-0.5 bg-primary-blue"} />
              ))}
            </div>
          }
        >
          <div className={""}>
            {SideBarLinks.map(
              (item, index) =>
                showSideBarItem(item) && (
                  <React.Fragment key={item.key}>
                    {Array.isArray(item.children) && item.children.length ? (
                      item.children.map((child) => (
                        <span
                          key={child.key}
                          onClick={() => handleClick(child.key)}
                          className="[&:not(:last-child)]:border-b hover:bg-gray-100 flex cursor-pointer pl-4 py-4 gap-2"
                        >
                          <Image
                            alt={child.title}
                            src={child.icon}
                            width={15}
                            height={15}
                            className={"w-[15px] h-[15px]"}
                          />
                          <span className="text-xs font-medium text-secondary-Black">
                            {child.title}
                          </span>
                        </span>
                      ))
                    ) : (
                      <span
                        key={index}
                        onClick={() => handleClick(item.key)}
                        className="[&:not(:last-child)]:border-b hover:bg-gray-100 flex cursor-pointer pl-4 py-4 gap-2"
                      >
                        <Image
                          alt={item.title}
                          src={item.icon}
                          width={15}
                          height={15}
                          className={`w-[15px] h-[15px] ${item.key === "rewards" ? "scale-y-[2]" : ""}`}
                        />
                        <span className="text-xs font-medium text-secondary-Black">
                          {item.title}
                        </span>
                      </span>
                    )}
                  </React.Fragment>
                ),
            )}
          </div>
        </Dropdown>
      </div>
      <div
        className={`flex h-[45px] items-center w-full justify-between ${styles.headerCont}`}
      >
        {isHomePage ? (
          <Tooltip
            content={"Token Creator earns 0.04 ETH once it reaches to Uniswap"}
          >
            <Button
              className={"m-auto uppercase"}
              onClick={() => dispatch(showPopUp("CreateCoinPopUp"))}
            >
              Create Token
            </Button>
          </Tooltip>
        ) : (
          <Image
            src={LEFT_ARROW_ICON_BLUE}
            alt="back"
            height={14}
            onClick={() => router.back()}
            width={14}
            className="cursor-pointer"
          />
        )}
        <div className={"flex gap-4 items-center h-full"}>
          <div
            className={
              "px-3 flex cursor-pointer justify-center items-center h-full min-w-[120px]  border-2 border-primary-blue rounded-[40px]"
            }
            onClick={() => router.push("/rewards")}
          >
            <Image
              src={Images.XP_COIN}
              className={""}
              alt={"XP"}
              width={32}
              height={32}
            />
            <p className={"text-primary-blue font-bold ml-2"}>
              {formatNumberWithCommas(totalPoints)} XP
            </p>
          </div>
          {account.status === "connected" ? (
            <WalletCart />
          ) : (
            <>
              {account.status === "reconnecting" ? (
                <div className={"flex items-center"}>
                  <Loading size={"6"} />
                  <span className={"ml-2 text-primary-blue"}>Connecting</span>
                </div>
              ) : (
                <Button className={"ml-auto uppercase"} onClick={() => open()}>
                  Connect Wallet
                </Button>
              )}
            </>
          )}
          <Image src={Images.BASE_LOGO} alt={"base"} width={32} height={32} />
        </div>
      </div>
    </>
  );
}
