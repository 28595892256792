import(/* webpackMode: "eager" */ "/workspace-install/app/context/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/components/AuthWrapper/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/components/Footer/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/components/Header/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/components/PopupHandler/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/components/Toast/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/modules/SideBar/index.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/workspace-install/redux/provider.tsx");
import(/* webpackMode: "eager" */ "/workspace-install/styles/globals.css");
import(/* webpackMode: "eager" */ "/workspace-install/styles/App.scss");
import(/* webpackMode: "eager" */ "/workspace-install/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}")