"use client";
import { useAppSelector } from "@/redux/hooks";
import { useRouter } from "next/navigation";

export default function Footer() {
  const siteName = useAppSelector((state) => state.Config.SITE_NAME);
  const router = useRouter();
  return (
    <div
      className={
        "w-full md:w-[calc(100%-100px)] text-xs md:text-sm text-primary-dark bg-primary-Background ml-auto py-2 gap-2 md:gap-0 flex flex-col md:flex-row"
      }
    >
      <p className={"text-center md:text-left pl-2 "}>
        © {siteName.toLowerCase()} 2024
      </p>
      <p className={"flex-1 text-center"}>
        This site is protected by reCAPTCHA and the{" "}
        <span
          className={"text-primary-blue hover:underline cursor-pointer"}
          onClick={() => router.push("/privacy-policy")}
        >
          Privacy Policy
        </span>{" "}
        and{" "}
        <span
          onClick={() => router.push("/terms-and-conditions")}
          className={"text-primary-blue hover:underline cursor-pointer"}
        >
          Terms of Service
        </span>{" "}
        apply.
      </p>
    </div>
  );
}
