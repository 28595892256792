"use client";
import styles from "./sidebar.module.scss";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { showPopUp } from "@/redux/reducers/PopUpsReducer";
import useWalletStatus from "@/customHooks/useWalletStatus";
import { toast } from "react-toastify";
import userOpenLink from "@/customHooks/userOpenLink";
import { useState, Fragment } from "react";
import { CHEVRON_ICON_DOWN, Images } from "@/utils/imageRelativePaths";
import { sideBarLink } from "@/types/global";

const FREE_TOOLS = [
  {
    title: "FOMO Trending",
    icon: "/images/icons/FomoTrendingBW.png",
    key: "trend_bot",
  },
  {
    title: "Telegram Buy Bot",
    icon: "/images/icons/TGbuybotBW.png",
    key: "buy_bot",
  },
];
export const SideBarLinks: sideBarLink[] = [
  { title: "Home", icon: "/images/icons/home.svg", key: "home" },
  {
    title: "Create Token",
    icon: "/images/icons/rocket.svg",
    key: "create_token",
  },
  {
    title: "XP Points",
    icon: "/assets/icons/Rewards.png",
    key: "rewards",
  },
  {
    title: "Fomo Bot",
    icon: "/assets/icons/FomoBot_BW.png",
    key: "fomo_bot",
    site: "fomo",
  },
  {
    title: "Free Tools",
    icon: "/images/icons/xpicons.png",
    key: "free_tools",
    site: "fomo",
    children: FREE_TOOLS,
  },
  {
    title: "Profile",
    icon: "/images/icons/create.svg",
    key: "tokens",
  },
  {
    title: "How it works",
    icon: "/images/icons/how.svg",
    key: "how_it_works",
  },

  {
    title: "Docs",
    icon: "/images/icons/folder-minus.svg",
    key: "docs",
  },
  {
    title: "Twitter",
    icon: "/images/icons/Twitter.svg",
    key: "twitter",
  },
  {
    title: "Telegram",
    icon: "/images/icons/Telegram.svg",
    key: "telegram",
  },
];
export default function SideBar() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { openLink } = userOpenLink();
  const { isConnected } = useWalletStatus(false);
  const [showTools, setShowTools] = useState(false);
  const user = useAppSelector((state) => state.Auth.user);
  const siteKey = useAppSelector((state) => state.Config.SITE_KEY);
  const isCeresSite = siteKey === "ceres";
  const handleClick = (key: string) => {
    switch (key) {
      case "home": {
        router.push("/");
        break;
      }
      case "tokens": {
        if (isConnected) {
          router.push(encodeURI(`/profile/${user?._id}?tab=COIN_CREATED`));
        } else {
          toast.error("Please Connect Wallet");
        }
        break;
      }
      case "rewards": {
        router.push("/rewards");
        break;
      }
      case "create_token": {
        dispatch(showPopUp("CreateCoinPopUp"));
        break;
      }
      case "how_it_works": {
        dispatch(showPopUp("HowItWorks"));
        break;
      }
      case "telegram": {
        openLink(isCeresSite ? "ceres_labs" : "FOMOCLICK", "telegram");
        break;
      }
      case "twitter": {
        openLink(
          isCeresSite
            ? "https://x.com/cereslabs_"
            : "https://x.com/fomodotclick",
          "twitter",
        );
        break;
      }
      case "docs": {
        if (isCeresSite) return;
        openLink("https://fomo-click.gitbook.io/fomo.click");
        break;
      }
      case "free_tools": {
        setShowTools((r) => !r);
        break;
      }
      case "fomo_bot": {
        openLink("https://t.me/FOMO_TradingBot");
        break;
      }
      case "trend_bot": {
        openLink("https://t.me/Fomo_Trending");
        break;
      }
      case "buy_bot": {
        openLink("https://t.me/FOMO_freebuybot");
        break;
      }
      default: {
      }
    }
  };
  const showSideBarItem = (link: sideBarLink) => {
    return !link.site || link.site === siteKey;
  };
  return (
    <div className={styles.sideBar}>
      <Image
        onClick={() => handleClick("home")}
        src={Images.FOMO_CLICK_LOGO as string}
        className={styles.appLogo}
        alt={"logo"}
        width={98}
        height={87}
      />
      <div className={styles.sideBarLinksCont}>
        {SideBarLinks.map(
          (link, index) =>
            showSideBarItem(link) && (
              <Fragment key={link.key}>
                <div
                  key={link.key}
                  onClick={() => handleClick(link.key)}
                  className={`${styles.eachlink} ${link.key === "docs" ? "mt-auto" : ""} ${link.title == "Home" ? styles["eachlink-active"] : ""}`}
                >
                  <div className={styles.wrapper}>
                    <Image
                      src={link.icon}
                      alt={"logo"}
                      className={styles[link.key]}
                      width={58}
                      height={57}
                    />
                  </div>
                  <p className={styles.title}>{link.title}</p>
                  {link.key === "free_tools" ? (
                    <Image
                      src={CHEVRON_ICON_DOWN}
                      alt="icon"
                      width={12}
                      height={6}
                      className={`scale-125 cursor-pointer ml-auto transition mr-2 ${!showTools ? "" : "rotate-180"}`}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {Array.isArray(link?.children) && link.children.length ? (
                  <div
                    className={`${styles.freeToolsCont} ${showTools ? styles.freeToolsOpen : styles.freeToolsClose}  ${showTools ? " bg-[#939FB829]" : ""}`}
                  >
                    {link.children.map((tool) => (
                      <div
                        key={tool.key}
                        onClick={() => handleClick(tool.key)}
                        className={`${styles.sublink} ml-4`}
                      >
                        <div className={styles.wrapper}>
                          <Image
                            src={tool.icon}
                            alt={"logo"}
                            className={styles[tool.key]}
                            width={58}
                            height={57}
                          />
                        </div>
                        <p className={styles.title}>{tool.title}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </Fragment>
            ),
        )}
      </div>
    </div>
  );
}
