"use client";
import Popup from "@/components/Popup/Popup";
import { useState } from "react";
import styles from "./report.module.scss";
import Button from "@/components/Button";
import { LEFT_ARROW_ICON_BLUE } from "@/utils/imageRelativePaths";
import Svg from "react-inlinesvg";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { hidePopUp } from "@/redux/reducers/PopUpsReducer";
import { reportPage } from "@/network/report";
import { usePathname } from "next/navigation";

export default function ReportPopUp() {
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const popupConfig = useAppSelector((state) => state.PopUps.config);
  let _id: string | undefined;
  if (popupConfig && "_id" in popupConfig) {
    _id = popupConfig?._id!;
  }
  const [desc, setDesc] = useState("");
  const closePopUp = () => {
    dispatch(hidePopUp("Report"));
  };
  const handleReport = async () => {
    if (_id) {
      const reqObj = {
        entityType: pathName.includes("/token/") ? "token" : "user",
        entityId: _id,
        description: desc,
      };
      const response = reportPage(reqObj);
      closePopUp();
    }
  };
  return (
    <Popup show={true} onClose={() => {}}>
      <div className={styles.mainCont}>
        <Svg
          src={LEFT_ARROW_ICON_BLUE}
          width={14}
          height={14}
          onClick={closePopUp}
          className={"cursor-pointer"}
        />
        <h2
          className={
            "text-[18px] text-center leading-5 font-semibold text-primary-dark mb-4"
          }
        >
          Report Page
        </h2>
        <textarea
          id="message"
          rows={4}
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          className="block p-2.5 text-primary-dark focus:outline-none w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
          placeholder="Write your concerns here........."
        ></textarea>

        <div className={"grid place-items-center mt-4"}>
          <Button className={"w-[180px]"} onClick={handleReport}>
            Submit Concern
          </Button>
        </div>
      </div>
    </Popup>
  );
}
