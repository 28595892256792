import styles from "./createcoin.module.scss";
import {
  Images,
  INFO_ICON,
  LEFT_ARROW_ICON_BLUE,
} from "@/utils/imageRelativePaths";
import { hidePopUp } from "@/redux/reducers/PopUpsReducer";
import Image from "next/image";
import React, { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import Button from "@/components/Button";
import { createCoinDataType, singleItem } from "@/types/global";
import Loading from "@/components/Loading";
import { useAccount } from "wagmi";
import Tooltip from "@/components/Tooltip";
import Radio from "@/components/Radio";
import Checkbox from "@/components/Checkbox";
import useMinAmountOut from "@/customHooks/useMinAmountOut";
import { formatNumberWithLetters } from "@/utils/helper";

const StyledButton = ({
  children,
  className = "",
  onClick,
}: {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={`text-secondary-Black cursor-pointer text-xs font-medium rounded bg-white px-2 py-1 ${className ?? ""}`}
    >
      {children}
    </div>
  );
};

const POSITION_OPTIONS: singleItem[] = [
  { key: "first", label: "First" },
  { key: "3", label: "Top 3" },
  { key: "10", label: "Top 10" },
];
const TIME_OPTIONS: singleItem[] = [
  { key: "1", label: "1 Hour" },
  { key: "2", label: "2 Hours" },
  { key: "4", label: "4 Hours" },
  { key: "8", label: "8 Hours" },
];
export default function Buy({
  handleFormSubmit,
  formData,
  setStep,
  loading,
  tax,
  setTax,
  error,
  setError,
}: {
  formData: createCoinDataType | null;
  handleFormSubmit: (a: string) => void;
  loading: boolean;
  setStep: (a: string) => void;
  setError: (a: string) => void;
  tax: number;
  error: string;
  setTax: (a: number) => void;
}) {
  const siteName = useAppSelector((state) => state.Config.SITE_NAME);
  const deadToken = useAppSelector((state) => state.Config?.config?.DEAD_TOKEN);
  const [selectedOption, setSelectedOption] = useState("standard");
  const [showTax, setShowTax] = useState(false);
  const [amt, setAmt] = useState<string>("0.000");

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };
  const getAmount = () => {
    return ((amt ? +parseFloat(amt) : 0) + 0.001).toFixed(4);
  };
  const minAmount = useMinAmountOut(
    "buy",
    deadToken ?? "0x420900882C0233fad47A79Ab877677d02766c4A8",
    false,
    amt ? parseFloat(amt) : 0,
    5,
  );

  const onQuickOptionClick = () => {};
  return (
    <div className={"p-1"}>
      <div className={"w-full flex items-center justify-between"}>
        <div>
          {!loading ? (
            <Image
              src={LEFT_ARROW_ICON_BLUE}
              alt="cross"
              width={12}
              height={12}
              className="cursor-pointer"
              onClick={() => setStep("create")}
            />
          ) : (
            ""
          )}
        </div>
        <Tooltip
          content={
            "Tip: its optional but buying a small amount of coins helps protect your coin from snipers"
          }
          position={"left"}
        >
          <Image src={INFO_ICON} alt="cross" width={24} height={24} />
        </Tooltip>
      </div>
      <p
        className={
          "font-bold text-sm md:text-[24px] m-auto  my-2 md:my-4 w-3/4 leading-4 md:leading-8 text-primary-dark text-center"
        }
      >
        Choose how many ${formData?.ticker} you want to buy (optional)
      </p>
      <div className={styles.coinInputCont}>
        <p className={"text-primary-dark text-sm"}>Token for Wallet</p>
        <div className={styles.inputCont}>
          <input
            type="number"
            placeholder={"Amount of ETH"}
            value={amt}
            onChange={(e) => setAmt(e.target.value)}
          />
          <span
            className={"text-base font-semibold  text-secondary-Black mr-2"}
          >
            {"ETH"}
          </span>
          <Image src={Images.ETH_ICON} alt={"eth"} width={24} height={24} />
        </div>
        <div className={"flex my-1 gap-2  items-center justify-between"}>
          <p className={" text-sm text-change-negative"}>{error}</p>
          <p className={" text-xs text-primary-dark"}>
            {minAmount.status === "success"
              ? `${formatNumberWithLetters(minAmount.result.data)} ${formData?.ticker ?? ""}`
              : "fetching number of tokens...."}
          </p>
        </div>
        <div className={"mt-2 flex flex-wrap gap-1"}>
          {[0.01, 0.02, 0.03, 0.04].map((item, index) => (
            <StyledButton
              key={index}
              onClick={() => {
                setAmt("" + item);
              }}
            >
              {item} ETH
            </StyledButton>
          ))}
        </div>
      </div>
      <div className={"my-4"}>
        <Checkbox
          checked={showTax}
          onChange={(e) => {
            setTax(e.target.checked ? 0 : 200);
            setShowTax(e.target.checked);
          }}
          label={"I want to opt out of crypto tax token"}
        />
      </div>
      {!showTax ? (
        <div className={styles.coinInputCont}>
          <p
            className={
              "font-semibold md:text-base text-sm text-secondary-Black"
            }
          >
            Crypto tax is the incentive given to dev from every trade placed by
            users. This will only be applied after token reaches to uniswap.
          </p>
          <p
            className={
              "mt-1 md:text-base text-sm font-semibold text-secondary-Black"
            }
          >
            <span>Note:</span> 0.25% tax value will be given to {siteName} out
            of this Tax value
          </p>
          <p className={"mt-4 font-normal text-sm text-[#151619]"}>Tax Value</p>
          <div className={"mt-2 flex items-center gap-2"}>
            {[1, 2, 3, 4].map((item, index) => (
              <Radio
                key={item}
                name={"user"}
                checked={tax === item * 100}
                onChange={(a) => setTax(item * 100)}
                label={item + "%"}
              />
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <p className={"text-center pr-4 font-bold text-primary-Black"}>
        Total Cost : {getAmount()} ETH
      </p>

      <div className={"grid mb-2 mt-2 place-content-center"}>
        {loading ? (
          <Loading size={"lg"} />
        ) : (
          <Button
            className={"mx-auto text-base"}
            disabled={false}
            onClick={() => handleFormSubmit(amt)}
          >
            Create Token
          </Button>
        )}
      </div>
    </div>
  );
}
