"use client";
import React, { useEffect, useRef, useState } from "react";
import Popup from "../Popup/Popup";
import CreateCoin from "@/components/CreateCoinPopUp/CreateCoin";
import Congrats from "@/components/CreateCoinPopUp/Congrats";
import Buy from "@/components/CreateCoinPopUp/Buy";
import { useWriteContract, useBalance } from "wagmi";
import { createToken, editToken } from "@/network/token";
import {
  AxiosError,
  createCoinDataType,
  hexString,
  tokenType,
} from "@/types/global";
import contractABI from "@/modules/SmartContracts/abis/basefun-abi.json";
import { getErrorMessage, getImageUrl } from "@/utils/helper";
import { BigNumber, ethers } from "ethers";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { toast } from "react-toastify";
import { refreshTokenData } from "@/redux/reducers/RefreshReducer";
import Animations from "@/components/Animations";
import type { ContractFunctionExecutionErrorType } from "viem";
import useVerifyChain from "@/customHooks/useVerifyChain";

type stepType = "create" | "buy" | "congrats";
type contractDataType = {
  name: string;
  ticker: string;
  description: string;
  image: string;
  url: string[];
  supply: number;
  max_tx: number;
};

const CreateCoinPopUp = ({ onPopupClose }: { onPopupClose: () => void }) => {
  const dispatch = useAppDispatch();
  const verifyChain = useVerifyChain();
  const config = useAppSelector((state) => state.PopUps.config);
  const CONTRACT_ADDRESS = useAppSelector(
    (state) => state.Config?.config?.BASEFUN_ADDRESS,
  ) as hexString;
  const [error, setError] = useState<string>("");
  const [tax, setTax] = useState<number>(200);
  const [isEdit, setEdit] = useState(false);
  const [editData, setEditData] = useState<createCoinDataType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPopUp, setPopUp] = useState<boolean>(true);
  const [step, setStep] = useState<stepType>("create");
  const [token, setToken] = useState<tokenType | null>(null);
  const [createFormData, setCreateFormData] =
    useState<null | createCoinDataType>(null);
  const { writeContractAsync } = useWriteContract();
  useEffect(() => {
    if (typeof config === "object" && Object.keys(config).length) {
      const tokenData = config as tokenType;
      setEditData({
        bgImageURL: getImageUrl(tokenData.bgImage),
        imageURL: getImageUrl(tokenData.image),
        name: tokenData.name,
        ticker: tokenData.ticker,
        description: tokenData.description,
        twitter: tokenData?.social?.twitter ?? "",
        telegram: tokenData?.social?.telegram ?? "",
        website: tokenData?.social?.website ?? "",
      } as createCoinDataType);
      setEdit(true);
    }
  }, [config]);
  const handlePopUpclose = () => {
    onPopupClose();
    setPopUp(!isPopUp);
  };
  const handleCreateFormSubmit = async (formdata: createCoinDataType) => {
    if (isEdit && formdata && config && "_id" in config) {
      const editTokenData: tokenType = await editToken(formdata, config?._id);
      if (editTokenData?._id) {
        toast.success("Token Details Updated Successfully");
        handlePopUpclose();
        dispatch(refreshTokenData());
      }
      return;
    }
    setCreateFormData(formdata);
    setStep("buy");
  };
  const writeToContract = async (
    data: {
      max_tx: number;
      image: string;
      ticker: string;
      name: string;
      creator_tax: number;
      description: string;
      url: string[];
    },
    amt: string,
  ) => {
    const BASE_TOKEN_CREATE_PRICE = 0.001;
    let amountToBuy;
    if (!!(amt && parseFloat(amt) > 0)) {
      amountToBuy = ethers.utils.parseEther(
        parseFloat(amt).toString(),
      ) as unknown as bigint;
    }

    // cannot but more than (5%)0.005
    if (amt && parseFloat(amt) > 0.05) {
      setError("you can't buy more than 5% of the total supply");
      return;
    }
    const createTokenAmount = ethers.utils.parseEther(
      amountToBuy
        ? (parseFloat(amt) + BASE_TOKEN_CREATE_PRICE).toString()
        : BASE_TOKEN_CREATE_PRICE.toString(),
    ) as unknown as bigint;
    try {
      const { name, ticker, description, image, url, max_tx, creator_tax } =
        data;

      // verify network
      const chainVerified = await verifyChain();
      if (!chainVerified) {
        toast.error("Trades can be placed only on BASE MAINNET");
        setError("Trades can be placed only on BASE MAINNET");
        setLoading(false);
        return;
      }
      let txHash;
      // create and buy token
      if (amountToBuy) {
        console.log("create and buy amountToBuy", amountToBuy.toString());
        console.log(
          "create and buy createTokenAmount",
          createTokenAmount.toString(),
        );
        txHash = await writeContractAsync({
          abi: contractABI,
          address: CONTRACT_ADDRESS,
          functionName: "createTokenandBuy",
          value: createTokenAmount, // buy + create
          args: [
            name,
            ticker,
            description,
            image,
            url,
            creator_tax,
            amountToBuy,
          ],
        });
      } else {
        console.log("create");
        txHash = await writeContractAsync({
          abi: contractABI,
          address: CONTRACT_ADDRESS,
          functionName: "createToken",
          value: createTokenAmount,
          args: [name, ticker, description, image, url, creator_tax],
        });
      }
      console.log("create", txHash);
      return txHash;
    } catch (e) {
      const reason = e as ContractFunctionExecutionErrorType;
      setError(reason.shortMessage);
      toast.error(reason.shortMessage, {
        position: "top-right",
      });
      console.log(e);
    }
  };
  const handleBuyFormSubmit = async (amt: string) => {
    if (createFormData) {
      setLoading(true);
      const createContractData = {
        name: createFormData.name,
        ticker: createFormData.ticker,
        description: createFormData.ticker,
        image: "",
        url: [
          createFormData?.telegram ?? "",
          createFormData?.twitter ?? "",
          createFormData?.website ?? "",
          "",
        ],
        max_tx: 50,
        creator_tax: tax ?? 0, // not more than max_tx
      };
      const transactionHash = await writeToContract(createContractData, amt);
      if (transactionHash) {
        createFormData["hash"] = transactionHash;
        const createdTokenData = await createToken(createFormData);
        if (createdTokenData?._id) {
          setToken(createdTokenData);
          setStep("congrats");
        }
      } else {
        setLoading(false);
        // handlePopUpclose();
      }

      // console.log(createdTokenData);
    }
    // setStep("congrats");
  };

  return (
    <>
      <Popup show={isPopUp} onClose={() => {}}>
        <div className="w-full md:w-[689px] z-[6] max-h-[90vh] p-8 pt-6 rounded-3xl bg-white text-[#41485C] relative overflow-y-scroll">
          {step === "create" && (
            <CreateCoin
              isEdit={isEdit}
              editData={editData}
              tokenData={createFormData}
              handlePopUpclose={handlePopUpclose}
              handleFormSubmit={handleCreateFormSubmit}
            />
          )}
          {step === "buy" && (
            <Buy
              formData={createFormData}
              loading={loading}
              tax={tax}
              setError={setError}
              error={error}
              setTax={setTax}
              setStep={(a: string) => setStep(a as stepType)}
              handleFormSubmit={handleBuyFormSubmit}
            />
          )}
          {step === "congrats" && (
            <Congrats
              imageUrl={getImageUrl(token?.image) ?? ""}
              id={token?.mint || ""}
            />
          )}
        </div>
        {step === "congrats" && (
          <Animations sound={true} animation={"create_coin"} />
        )}
      </Popup>
    </>
  );
};

export default CreateCoinPopUp;
