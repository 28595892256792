import axios from "@/utils/axios";
import {
  AxiosError,
  createCoinDataType,
  tokensResponse,
  tokenTradeResponse,
  tokenType,
  tradesResponse,
} from "@/types/global";
import { getErrorMessage } from "@/utils/helper";
import { toast } from "react-toastify";
import { barDataType, symbolInfoType } from "@/types/chart";
import { paginationConfigType } from "@/types/component";
import { MyHoldingsResponse } from "@/types/response";

export async function createToken(data: createCoinDataType) {
  const formData = new FormData();
  [
    "name",
    "ticker",
    "description",
    "image",
    "bgImage",
    "twitter",
    "telegram",
    "website",
    "hash",
  ].forEach((key: string) => {
    const value = data[key as keyof createCoinDataType];
    if (value && value !== "") {
      formData.append(key, value as string | Blob);
    }
  });
  try {
    const response = await axios.post("/tokens", formData);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function editToken(data: createCoinDataType, id: string) {
  const formData = new FormData();
  ["name", "description", "twitter", "telegram", "website"].forEach(
    (key: string) => {
      const value = data[key as keyof createCoinDataType];
      if (value && value !== "" && typeof value === "string") {
        formData.append(key, value);
      }
    },
  );
  ["image", "bgImage"].forEach((key: string) => {
    const value = data[key as keyof createCoinDataType];
    if (value && typeof value === "object") {
      formData.append(key, value as Blob);
    }
  });
  console.log(formData);
  try {
    const response = await axios.patch("/tokens/" + id, formData);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTokenDetails(
  mint: string,
): Promise<tokenType | undefined> {
  try {
    const response = await axios.get("/tokens/" + mint);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getMyTokenHoldings(
  mint: string,
): Promise<undefined | MyHoldingsResponse> {
  try {
    const response = await axios.get("/tokens/" + mint + "/my-holdings");
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTokenHolders(mint: string) {
  try {
    const response = await axios.get("/tokens/" + mint + "/top-holders");
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}
export async function searchTokenDetails(query: string) {
  try {
    const response = await axios.get("/tokens/search?query=" + query);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTransactionAmount(data: object) {
  try {
    let query = "";
    Object.entries(data).forEach(([key, value], index) => {
      query = query + `${index == 0 ? "?" : "&"}` + `${key}=${value}`;
    });
    const response = await axios.get("/tokens/conversion" + query);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getSortedTokens(
  key: string,
  search: string,
  pagination?: paginationConfigType,
  other?: string,
  firstData: boolean = false,
): Promise<tokensResponse | undefined> {
  let sortBy = "",
    sort = "";
  switch (key) {
    case "mc_h2l": {
      sortBy = "marketCap";
      sort = "DESC";
      break;
    }
    case "mc_l2h": {
      sortBy = "marketCap";
      sort = "ASC";
      break;
    }
    case "time": {
      sortBy = "createdAt";
      sort = "DESC";
      break;
    }
    case "bump_order": {
      sortBy = "bumpAt";
      sort = "DESC";
      break;
    }
    default: {
    }
  }
  try {
    const searchQuery =
      search && search !== "" && search.length > 2
        ? `search=${search}&`
        : undefined;
    let paginationQuery = "";
    if (pagination && pagination.action) {
      paginationQuery = `${pagination.action}Cursor=${pagination[pagination.action]}`;
    }
    console.log("pagination", pagination, paginationQuery);
    const response = await axios.get(
      `tokens?${paginationQuery.length ? paginationQuery + "&" : ""}${searchQuery ?? ""}sortBy=${sortBy}&sort=${sort}${other ? "&" + other : ""}`,
    );
    return response.data;
  } catch (e) {
    console.log("Error");
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getRocketsInFLight(): Promise<undefined | tokenType[]> {
  try {
    const response = await axios.get("/tokens/rockets-in-flight");
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function bookmarkToken(id: string) {
  try {
    const response = await axios.post("/bookmarks/tokens/" + id);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getBookmarkedToken(
  isMyProfile: boolean,
  page = 1,
): Promise<tokensResponse | undefined> {
  try {
    if (!isMyProfile) return { hasMoreData: false, tokens: [] };
    const response = await axios.get("/bookmarks/tokens?page=" + page);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function deleteBookmarkToken(id: string) {
  try {
    const response = await axios.delete("/bookmarks/tokens/" + id);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTokenSymbolInfo(
  id: string = "",
): Promise<symbolInfoType | undefined> {
  try {
    const response = await axios.get("/tokens/" + id + "/chart-info");
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTokenPriceData(
  id: string = "",
  interval = "1m",
  chart_type: "fomo" | "uniswap",
): Promise<barDataType[] | undefined> {
  try {
    const response = await axios.get(
      "/tokens/" +
        id +
        "/price-history?interval=" +
        interval +
        `&source=${chart_type === "uniswap" ? "UNISWAP" : "FOMO_CLICK"}`,
    );
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTokenTrades(
  id: string = "",
  page = 1,
): Promise<tokenTradeResponse | undefined> {
  try {
    const response = await axios.get("/tokens/" + id + "/trades?page=" + page);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}
