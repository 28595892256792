"use client";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { cookieStorage, createStorage } from "wagmi";
import { baseSepolia } from "wagmi/chains";
import { customChain } from "./chain";

// Get projectId from https://cloud.walletconnect.com
export const projectId = "ccaec08d757733ea2cc9e26a4ab77912";

if (!projectId) throw new Error("Project ID is not defined");

const metadata = {
  name: "Fomo.click",
  description: "Fomo.click",
  url: "https://dev-web-mono-fomo-click-service.chingari.io/", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

// Create wagmiConfig
const chains = [
  process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
    ? baseSepolia
    : customChain,
] as const;
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
});
