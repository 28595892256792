import { useRouter } from "next/navigation";
import { validateURL } from "@/utils/helper";

export default function userOpenLink() {
  const router = useRouter();

  const openTransaction = (hash: string = "") => {
    window.open(
      process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL + "/tx/" + hash,
      "_blank",
    );
  };

  const openAddress = (address: string = "") => {
    window.open(
      process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL + "/address/" + address,
      "_blank",
    );
  };

  const openProfile = (username: string = "") => {
    if (username === "" || typeof username !== "string") return;
    router.push("/profile/" + username);
  };

  const openLink = (
    link: string,
    type?: "twitter" | "website" | "telegram",
  ) => {
    if (typeof link !== "string" || link === "") return;
    let finalLink = "";
    if (validateURL(link)) {
      finalLink = link;
    } else {
      switch (type) {
        case "twitter": {
          finalLink = `https://x.com/${link}`;
          break;
        }
        case "telegram": {
          finalLink = `https://t.me/${link}`;
          break;
        }
        case "website": {
          finalLink = `https://${link}`;
          break;
        }
      }
    }
    window.open(finalLink, "_blank");
  };
  return {
    openTransaction,
    openProfile,
    openAddress,
    openLink,
  };
}
