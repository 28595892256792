"use client";
import React, { useEffect, useRef, useState } from "react";
import Popup from "../Popup/Popup";
import Image from "next/image";
import { Images } from "@/utils/imageRelativePaths";
import Button from "@/components/Button";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { hidePopUp } from "@/redux/reducers/PopUpsReducer";
import { updateUser } from "@/network/auth";
import { setUser } from "@/redux/reducers/AuthReducer";
import { getImageUrl, validateURL, validateUserName } from "@/utils/helper";
import { toast } from "react-toastify";
import { KeyValueStringType } from "@/types/global";
import { useRouter } from "next/navigation";

const ProfilePopUp = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const initialData = {
    profilePic: "",
    username: "",
    profilePicUrl: "",
    bio: "",
    twitter: "",
    website: "",
  };
  const initialErrors = {
    username: "",
    twitter: "",
    website: "",
  };
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState<KeyValueStringType>(initialErrors);
  const profilePhotoRef = useRef<HTMLInputElement | null>(null);
  const [isPopUp, setPopUp] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      setFormData((data) => {
        let modifiedData = { ...data, ...user };
        if (!modifiedData.username || modifiedData.username === "") {
          modifiedData.username = modifiedData.address?.substring(2, 8) ?? "";
        }
        if (user?.profilePic?.length) {
          modifiedData.profilePic = getImageUrl(user.profilePic) || "";
          modifiedData.profilePicUrl = getImageUrl(user.profilePic) || "";
        }
        if (user.social) {
          modifiedData = { ...modifiedData, ...user.social };
        }
        return modifiedData;
      });
    }
  }, [user]);
  const handlePopUpclose = () => {
    //if (!user || !user.username) {
    setPopUp(!isPopUp);
    dispatch(hidePopUp("Profile"));
  };

  const onCloseClick = () => {
    if (user && user.username) {
      handlePopUpclose();
    } else {
      toast.error("Please Enter User Details");
    }
  };
  const handlePhotoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (e.target.files && e.target.files[0]) {
      const photo = e.target.files[0];
      setFormData((prevState) => ({
        ...prevState,
        [type]: photo,
        profilePicUrl: URL.createObjectURL(photo),
      }));
      setErrors((prevState) => ({
        ...prevState,
        [type]: "",
      }));
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      username: validateUserName(formData.username),
      twitter: "",
      website: "",
    };
    console.log(newErrors);
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error.length === 0);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      let resultData = formData;
      console.log(resultData);
      const response = await updateUser(resultData);
      if (response?._id) {
        dispatch(setUser(response));
        handlePopUpclose();
        toast.success("User Updated Successfully");
        router.push("/profile/" + response.username);
        // dispatch(refreshUserData());
      }
      console.log(response);
      // setFormData(initialData);
      // setErrors(initialErrors);
      if (profilePhotoRef.current) {
        profilePhotoRef.current.value = "";
      }
    } else {
      console.error("Validation failed");
    }
  };
  return (
    <Popup show={isPopUp} onClose={() => {}}>
      <div className="w-full md:w-[689px] max-h-[90vh] p-8 pt-6 rounded-3xl bg-white text-[#41485C] relative overflow-y-scroll">
        <Image
          src={Images.iconCross}
          alt="cross"
          width={12}
          height={12}
          className="absolute cursor-pointer top-[28px] right-[34px]"
          onClick={onCloseClick}
        />
        <p className="text-center text-xl font-medium">
          {user?.username && user?.username?.length > 0 ? "Edit" : "Create"}{" "}
          User Profile
        </p>
        <form
          className="w-full mt-[72px] flex flex-col items-center gap-8"
          onSubmit={handleSubmit}
        >
          <div className="w-full flex flex-col gap-5">
            <div className="w-[107px] h-[107px] bg-[#F3F6FF] border-4 border-white rounded-full mx-auto relative">
              <input
                type="file"
                accept="image/*"
                ref={profilePhotoRef}
                onChange={(e) => handlePhotoChange(e, "profilePic")}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
              {formData.profilePicUrl ? (
                <img
                  src={formData.profilePicUrl}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full"
                />
              ) : (
                <img
                  src={Images.FOMO_CLICK_IMG}
                  alt="logo"
                  className="w-full h-full object-cover rounded-full"
                />
              )}
              <Image
                src={Images.IC_UPLOAD}
                alt="upload"
                width={24}
                height={24}
                className="absolute bottom-0 -right-1"
                onClick={() => profilePhotoRef.current?.click()}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="username"
                className={`text-sm font-normal text-[#41485C] opacity-60 ${
                  !formData.username ? "invisible" : ""
                }`}
              >
                User Name
              </label>
              <input
                type="text"
                placeholder="User Name"
                name="username"
                className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
                value={formData.username}
                onChange={handleInputChange}
              />
              {errors.username && (
                <p className="text-red-500 text-xs">{errors.username}</p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="bio"
                className={`text-sm font-normal text-[#41485C] opacity-60 ${
                  !formData.bio ? "invisible" : ""
                }`}
              >
                Bio
              </label>
              <input
                type="text"
                placeholder="Bio"
                name="bio"
                className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
                value={formData.bio}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="twitter"
                className={`text-sm font-normal text-[#41485C] opacity-60 ${
                  !formData.twitter ? "invisible" : ""
                }`}
              >
                Twitter (Optional)
              </label>
              <input
                type="text"
                placeholder="Twitter (Optional)"
                name="twitter"
                className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
                value={formData.twitter}
                onChange={handleInputChange}
              />
              {errors.twitter && (
                <p className="text-red-500 text-xs">{errors.twitter}</p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="website"
                className={`text-sm font-normal text-[#41485C] opacity-60 ${
                  !formData.website ? "invisible" : ""
                }`}
              >
                Telegram (Optional)
              </label>
              <input
                type="text"
                placeholder="Telegram (Optional)"
                name="website"
                className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
                value={formData.website}
                onChange={handleInputChange}
              />
              {errors.website && (
                <p className="text-red-500 text-xs">{errors.website}</p>
              )}
            </div>
          </div>
          <Button type="submit">Save</Button>
        </form>
      </div>
    </Popup>
  );
};

export default ProfilePopUp;
