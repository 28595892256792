"use client";
import Popup from "@/components/Popup/Popup";
import React, { useState } from "react";
import styles from "./howitworks.module.scss";
import Button from "@/components/Button";
import {
  FOMO_CLOCK,
  Images,
  LEFT_ARROW_ICON_BLUE,
} from "@/utils/imageRelativePaths";
import Svg from "react-inlinesvg";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { hidePopUp, showPopUp } from "@/redux/reducers/PopUpsReducer";
import Content from "./data";
import TwinTabs from "@/components/TwinTabs";
import { singleItem } from "@/types/global";
import { useRouter } from "next/navigation";
import Image from "next/image";

export default function HowItWorks() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [user, setUser] = useState<"trader" | "creator">("creator");
  const isTrader = user === "trader";
  const siteKey = useAppSelector((state) => state.Config.SITE_KEY);
  const isCeresSite = siteKey === "ceres";
  const closePopUp = () => {
    dispatch(hidePopUp("HowItWorks"));
    if (isTrader) {
      router.push("/");
    } else {
      dispatch(showPopUp("CreateCoinPopUp"));
    }
  };
  const TabOptions: singleItem[] = [
    { label: "Trader", key: "trader" },
    { label: "Creator", key: "creator" },
  ];
  const handleButtonClick = () => {};
  return (
    <Popup show={true} onClose={() => {}}>
      <div className={styles.mainCont}>
        <Image
          src={Images.iconCross}
          alt="cross"
          width={12}
          height={12}
          className="absolute cursor-pointer top-[28px] right-[34px]"
          onClick={() => dispatch(hidePopUp("HowItWorks"))}
        />
        <div className={styles.contentCont}>
          <TwinTabs
            options={TabOptions}
            value={user}
            onChange={(a) => setUser(a as "trader" | "creator")}
          />
          <article
            className="flex-1 overflow-scroll mt-4 prose prose-base marker:text-secondary-Black prose-a:no-underline prose-a:font-normal prose-a:text-base prose-a:text-secondary-Black prose-p:mb-0 prose-p:text-secondary-Black prose-li:text-secondary-Black prose-li:my-0 w-full prose:ul:list-disc"
            dangerouslySetInnerHTML={{ __html: Content[user] }}
          />
        </div>
        <div className={"grid place-items-center mt-4"}>
          <Button
            className={`w-[180px] h-[54px] text-base flex justify-center items-center`}
            onClick={closePopUp}
          >
            {isTrader && (
              <Image
                className={"mr-2"}
                src={FOMO_CLOCK}
                alt={"Clock"}
                width={32}
                height={32}
              />
            )}
            {isTrader ? "FOMO IN" : "Create Token"}
          </Button>
        </div>
      </div>
    </Popup>
  );
}
