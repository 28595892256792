import Image from "next/image";
import { Images } from "@/utils/imageRelativePaths";
import Button from "@/components/Button";
import React, { useEffect, useRef, useState } from "react";
import {
  createCoinDataType,
  KeyValueStringBoolType,
  KeyValueStringType,
  tokenType,
} from "@/types/global";
import { useAppSelector } from "@/redux/hooks";
import { validateTicker, validateURLWithoutHttp } from "@/utils/helper";

export default function CreateCoin({
  handlePopUpclose,
  handleFormSubmit,
  isEdit,
  editData,
  tokenData,
}: {
  handlePopUpclose: () => void;
  isEdit: boolean;
  editData: createCoinDataType | null;
  tokenData: createCoinDataType | null;
  handleFormSubmit: (a: createCoinDataType) => void;
}) {
  const initialData: createCoinDataType = {
    bgImage: {},
    image: {},
    bgImageURL: "",
    imageURL: "",
    name: "",
    ticker: "",
    description: "",
    twitter: "",
    telegram: "",
    website: "",
  };

  const initialErrors: KeyValueStringType = {
    name: "",
    ticker: "",
    description: "",
    bgImage: "",
    image: "",
  };
  const config = useAppSelector((state) => state.PopUps.config);
  const [formData, setFormData] = useState<createCoinDataType>(initialData);

  const [errors, setErrors] = useState<KeyValueStringType>(initialErrors);
  const coverPhotoRef = useRef<HTMLInputElement | null>(null);
  const profilePhotoRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  useEffect(() => {
    if (tokenData) {
      setFormData(tokenData);
    }
  }, [tokenData]);
  const handlePhotoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    if (e.target.files && e.target.files[0]) {
      const photo = e.target.files[0];
      setFormData((prevState) => ({
        ...prevState,
        [type]: photo,
        [type + "URL"]: URL.createObjectURL(photo),
      }));
      setErrors((prevState) => ({
        ...prevState,
        [type]: "",
      }));
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors: KeyValueStringType = {
      name: formData.name ? "" : "Token Name is required",
      description: formData.description ? "" : "Description is required",
    };
    if (!isEdit) {
      newErrors["image"] = !formData.imageURL ? "Token Photo is required" : "";
      newErrors["ticker"] = validateTicker(formData.ticker);
    }
    if (formData?.website?.length > 0) {
      newErrors["website"] = !validateURLWithoutHttp(formData?.website)
        ? "Invalid URL"
        : "";
    }
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error.length === 0);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      let resultData = formData;
      console.log(resultData);
      handleFormSubmit(resultData);
      setFormData(initialData);
      setErrors(initialErrors);
      if (coverPhotoRef.current) {
        coverPhotoRef.current.value = "";
      }
      if (profilePhotoRef.current) {
        profilePhotoRef.current.value = "";
      }
    } else {
      console.error("Validation failed");
    }
  };
  return (
    <>
      <Image
        src={Images.iconCross}
        alt="cross"
        width={12}
        height={12}
        className="absolute cursor-pointer top-[28px] right-[34px]"
        onClick={handlePopUpclose}
      />
      {!isEdit && (
        <p className="text-center text-xs md:text-base font-medium">
          It will take ~0.001 ETH to launch a Token
        </p>
      )}
      <form
        className="w-full mt-[37px] flex flex-col items-center gap-16"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col gap-5">
          <div
            className={`w-full h-[154px] ${
              !formData.bgImage && "border border-dashed border-[#939FB8]"
            } rounded-[9px] bg-[#939FB814] relative mb-8`}
          >
            {formData.bgImageURL ? (
              <img
                src={formData.bgImageURL}
                alt="Cover"
                className="w-full h-full object-cover rounded-[9px]"
              />
            ) : (
              <div className="flex items-center justify-center gap-4 mt-8">
                <Image
                  src={Images.IC_EMPTY_IMAGE}
                  alt="empty-image"
                  width={52}
                  height={45}
                />
                <div className={"flex justify-center items-center flex-col"}>
                  <p className="font-medium md:text-base text-sm text-[#303649]">
                    Upload your Banner here
                  </p>
                  <p className="font-normal mt-1 text-xs text-secondary-Black">
                    Dimensions : 1500 X 500
                  </p>
                </div>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              ref={coverPhotoRef}
              onChange={(e) => handlePhotoChange(e, "bgImage")}
              className="absolute inset-0 opacity-0 cursor-pointer"
            />
            <div className="w-[104px] h-[104px] bg-gray-50 border-4 border-white rounded-full absolute -bottom-10 left-1/2 transform -translate-x-1/2">
              <input
                type="file"
                accept="image/*"
                ref={profilePhotoRef}
                onChange={(e) => handlePhotoChange(e, "image")}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
              {formData.imageURL ? (
                <img
                  src={formData.imageURL}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full"
                />
              ) : (
                <img
                  src={Images.FOMO_CLICK_IMG}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full"
                />
              )}
              <Image
                src={Images.IC_UPLOAD}
                alt="upload"
                width={29}
                height={29}
                className="absolute -right-2 bottom-2"
                onClick={() => profilePhotoRef.current?.click()}
              />
            </div>
            {errors.bgImage && (
              <p className="text-red-500 text-xs absolute -bottom-5 left-0 w-full">
                {errors.bgImage}
              </p>
            )}
            {errors.image && (
              <p className="text-red-500 text-xs absolute -bottom-10 left-0 w-full">
                {errors.image}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="name"
              className={`text-sm font-normal text-[#41485C] opacity-60 ${
                !formData.name ? "invisible" : ""
              }`}
            >
              Token Name
            </label>
            <input
              type="text"
              placeholder="Token Name"
              name="name"
              className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && (
              <p className="text-red-500 text-xs">{errors.name}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="ticker"
              className={`text-sm font-normal text-[#41485C] opacity-60 ${
                !formData.ticker ? "invisible" : ""
              }`}
            >
              Ticker
            </label>
            <div
              className={"flex items-center w-full border-b border-[#939FB880]"}
            >
              {isEdit ? (
                <p
                  className={
                    "flex-1 cursor-not-allowed  py-2.5  text-md font-normal text-[#41485C]"
                  }
                >
                  {formData.ticker}
                </p>
              ) : (
                <input
                  type="text"
                  placeholder="Ticker"
                  name="ticker"
                  disabled={isEdit}
                  className={`flex-1  py-2.5 outline-none ${isEdit ? "bg-gray-100 cursor-not-allowed" : "bg-white"}`}
                  value={formData.ticker}
                  onChange={handleInputChange}
                />
              )}
              <span
                className={`opacity-60 text-sm ${(formData?.ticker?.length || 0) > 10 ? "text-red-600" : "text-secondary-Black"}`}
              >
                {formData?.ticker?.length ?? 0}/10 Characters
              </span>
            </div>
            {errors.ticker && (
              <p className="text-red-500 text-xs">{errors.ticker}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="description"
              className={`text-sm font-normal text-[#41485C] opacity-60 ${
                !formData.description ? "invisible" : ""
              }`}
            >
              Description
            </label>
            <textarea
              placeholder="Description"
              name="description"
              className="w-full h-[78px] border-b border-[#939FB880] bg-white py-2.5 outline-none resize-none overflow-y-scroll"
              value={formData.description}
              onChange={handleInputChange}
            />
            {errors.description && (
              <p className="text-red-500 text-xs">{errors.description}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="twitter"
              className={`text-sm font-normal text-[#41485C] opacity-60 ${
                !formData.twitter ? "invisible" : ""
              }`}
            >
              Twitter (Optional)
            </label>
            <input
              type="text"
              placeholder="Twitter (Optional)"
              name="twitter"
              className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
              value={formData.twitter}
              onChange={handleInputChange}
            />
            {errors.twitter && (
              <p className="text-red-500 text-xs">{errors.twitter}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="telegram"
              className={`text-sm font-normal text-[#41485C] opacity-60 ${
                !formData.telegram ? "invisible" : ""
              }`}
            >
              Telegram (Optional)
            </label>
            <input
              type="text"
              placeholder="Telegram (Optional)"
              name="telegram"
              className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
              value={formData.telegram}
              onChange={handleInputChange}
            />
            {errors.telegram && (
              <p className="text-red-500 text-xs">{errors.telegram}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="website"
              className={`text-sm font-normal text-[#41485C] opacity-60 ${
                !formData.website ? "invisible" : ""
              }`}
            >
              Website (Optional)
            </label>
            <input
              type="text"
              placeholder="Website (Optional)"
              name="website"
              className="w-full border-b border-[#939FB880] bg-white py-2.5 outline-none	"
              value={formData.website}
              onChange={handleInputChange}
            />
            {errors.website && (
              <p className="text-red-500 text-xs">{errors.website}</p>
            )}
          </div>
        </div>
        <Button type="submit">{isEdit ? "Save Changes" : "Next"}</Button>
      </form>
    </>
  );
}
