import React, { ChangeEvent } from "react";
import styles from "./radio.module.scss";

const RadioButton = ({
  name,
  value,
  checked,
  onChange,
  label,
}: {
  name: string;
  value?: string;
  checked: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  label: string;
}) => {
  return (
    <div className="flex items-center">
      <input
        type="radio"
        name={name}
        value={value}
        id={label}
        checked={checked}
        onChange={onChange}
        className={styles["custom-radio"]}
      />
      <label
        htmlFor={label}
        className="ml-2 md:text-base text-sm text-[#151619]"
      >
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
