import Button from "@/components/Button";
import React from "react";
import { useRouter } from "next/navigation";
import { useAppDispatch } from "@/redux/hooks";
import styles from "./createcoin.module.scss";
import { hidePopUp } from "@/redux/reducers/PopUpsReducer";
import {
  CELEBRATION,
  CONGRATS,
  CELEBRATION_BACKGROUND,
  Images,
} from "@/utils/imageRelativePaths";
import Image from "next/image";

export default function Congrats({
  id = "",
  imageUrl,
}: {
  id: string;
  imageUrl: string;
}) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const handleClick = async () => {
    dispatch(hidePopUp("CreateCoinPopUp"));
    router.push("/token/" + id);
  };
  return (
    <>
      <Image
        src={Images.iconCross}
        alt="cross"
        width={16}
        height={16}
        className="absolute cursor-pointer top-[30px] right-[30px]"
        onClick={() => dispatch(hidePopUp("CreateCoinPopUp"))}
      />
      <div className={"relative"}>
        <Image
          className={
            "absolute top-[50%] left-[50%] transform scale-125 -translate-x-1/2 -translate-y-1/2"
          }
          src={CELEBRATION_BACKGROUND}
          alt={"celebration"}
          width={250}
          height={80}
        />
        <div className={styles.congratsCont}>
          <Image
            src={CELEBRATION}
            alt={"celebration"}
            width={110}
            height={110}
          />
          <Image src={CONGRATS} alt={"celebration"} width={250} height={80} />

          <Image
            src={CELEBRATION}
            alt={"celebration"}
            width={110}
            height={110}
          />
        </div>
        <div className={styles.coinIconCont}>
          <Image src={imageUrl} alt={"cat"} width={168} height={57} />
        </div>
        <p
          className={
            "font-bold text-lg md:text-[24px] m-auto my-4 w-3/4 leading-5 md:leading-8 text-primary-dark text-center"
          }
        >
          Your Meme Token has been created successfully
        </p>
        <div className={"grid my-4 place-content-center"}>
          <Button
            className={"mx-auto text-base font-bold"}
            onClick={handleClick}
          >
            View Token Page
          </Button>
        </div>
      </div>
    </>
  );
}
