import axios from "@/utils/axios";
import {
  activityData,
  activityType,
  AxiosError,
  earningsType,
  followersResponse,
  leaderBoardDataType,
  myRewardsType,
  referralType,
  taskType,
  tokensResponse,
  tokenType,
  tradesResponse,
} from "@/types/global";
import { getErrorMessage } from "@/utils/helper";
import { toast } from "react-toastify";

export async function getRewardTasks(): Promise<taskType[] | undefined> {
  try {
    const response = await axios.get(`/rewards/tasks`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function completeRewardTask(
  id: string = "",
): Promise<"OK" | undefined> {
  try {
    const response = await axios.post(`/rewards/tasks/` + id);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getRewardActivities(): Promise<activityData | undefined> {
  try {
    const response = await axios.get(`/rewards/activities`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getReferrals(): Promise<referralType | undefined> {
  try {
    const response = await axios.get(`/rewards/referrals`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getEarnings(): Promise<earningsType[] | undefined> {
  try {
    const response = await axios.get(`/rewards/history?page=1`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getMyRewards(): Promise<myRewardsType | undefined> {
  try {
    const response = await axios.get(`/rewards`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getRewardsLeaderBoard(): Promise<
  leaderBoardDataType | undefined
> {
  try {
    const response = await axios.get(`/rewards/leaderboards`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}
