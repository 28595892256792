import axios from "@/utils/axios";
import Axios from "axios";
import { getErrorMessage, parseJwt } from "@/utils/helper";
import { toast } from "react-toastify";
import { AxiosError, configResponseType, userType } from "@/types/global";

export const loginUser = async (
  address: string,
  signature: string,
  timestamp: number,
  referralCode: string | null,
) => {
  const data: {
    address: string;
    signature: string;
    timestamp: number;
    referralCode?: string;
  } = {
    address,
    signature,
    timestamp,
  };
  if (referralCode) {
    data["referralCode"] = referralCode;
  }
  try {
    const loginResponse = await Axios.post(
      process.env.NEXT_PUBLIC_API_URL + "/auth/login",
      data,
    );
    return loginResponse.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
};

export const getMyDetails = async (): Promise<userType | undefined> => {
  try {
    const response = await axios.get(`/users/me`);
    console.log("my details", response.data);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
};
export const getConfig = async (): Promise<undefined | configResponseType> => {
  try {
    const response = await axios.get(`/configs`);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
};

export const updateUser = async (userObj: {
  username: string;
  bio: null | string;
  profilePic: string;
  website: string;
  twitter: string;
}) => {
  try {
    let data = new FormData();
    data.append("username", userObj.username);
    data.append("bio", userObj.bio ?? "");
    ["twitter", "website"].forEach((key) => {
      const val = userObj[key as keyof typeof userObj];
      if (val && val !== "") {
        data.append(key, val);
      }
    });
    if (userObj.profilePic && typeof userObj.profilePic === "object") {
      data.append("image", userObj.profilePic);
    }
    const response = await axios.patch("/users/me", data);
    console.log("my details", response.data);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    console.log(errorMsg);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
};
