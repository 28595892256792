import { DropdownPositionENUM } from "@/types/component";
import { twMerge } from "tailwind-merge";
import { useRef, useState } from "react";
import useOnClickOutside from "@/customHooks/useOnClickOutside";
interface DropdownPropType {
  children: React.ReactNode;
  position?: DropdownPositionENUM;
  parentContainer: React.ReactNode;
  onClose?: () => void;
  className?: string;
  mode?: "click" | "hover";
  closeOnParentClick?: boolean;
}
const Dropdown = ({
  children,
  position = DropdownPositionENUM.BOUTTOM_RIGHT,
  parentContainer,
  className,
  onClose,
  mode = "click",
  closeOnParentClick = true,
}: DropdownPropType) => {
  const parentContRef = useRef<HTMLDivElement>(null);
  const isClick = mode === "click";
  const [open, setOpen] = useState(false);
  useOnClickOutside(parentContRef, () => {
    if (isClick) {
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }
  });
  return (
    <div
      className="relative main-class"
      ref={parentContRef}
      onClick={(e) => {
        if (isClick) {
          const currentTarget = e.target as HTMLElement;
          const isClose = currentTarget.getAttribute("aria-action") === "close";
          if (isClose) {
            setOpen(false);
            if (onClose) {
              // onClose();
            }
            return;
          }
          setOpen((r) => {
            return closeOnParentClick ? !r : true;
          });
        }
      }}
    >
      {parentContainer}

      <div
        className={twMerge(
          `${open ? " flex " : "hidden"} ${!isClick ? "children-class" : ""} z-[2] flex-col w-[200px]  absolute`,
          className ?? "",
        )}
      >
        <div className="h-2 bg-transparent w-full" />
        <div
          className={`bg-[#FFFFFF] overflow-hidden shadow-[0px_2px_8px_0px_#272C3C1A] rounded-[10px] z-10 ${position} `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
