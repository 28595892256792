import { store } from "@/redux/store";

const siteName = store.getState().Config.SITE_NAME;
const siteKey = store.getState().Config.SITE_KEY;
const isCeresSite = siteKey === "ceres";
const data = {
  trader: `
  <p><b>${siteName}</b>  is a fair-launch meme token launchpad with no presale and no team allocation. Follow these easy steps to find your next gem:</p>
      <ul>
        <li><b>Step 1:</b> Find a token using our search function and filters.</li>
        <li><b>Step 2:</b> Buy a token on the Bonding Curve from the token page ${isCeresSite ? "" : "or via FOMObot, our lightning fast trading bot"}.</li>
        <li><b>Step 3:</b> Sell at any time to book profits or losses.</li>
      
        <li><b>Step 4:</b> When the bonding curve reaches a market cap of $30K, $5K of liquidity is deposited in Uniswap and burned.</li>
        <li><b>Step 5:</b> ${isCeresSite ? "Earn XP points by completing tasks, daily activities and referring fun.Ceres to others. XP points will be used to claim a future airdrop." : "Earn XP points by completing tasks, daily activities and referring FOMO to others. XP points will be used to claim a future airdrop."}  </li>
      </ul>
  `,
  creator: `
      <ul>
        <p><b>CREATE A TOKEN </b></p>
        <li><b>Step 1:</b> Click “Create Token” </li>
        <li><b>Step 2:</b> Enter token details (name, ticker, socials).</li>
        <li><b>Step 3:</b> Purchase a portion of your tokens at launch to protect against snipers.</li>
        <li><b>Step 4:</b> Set developer tax (0%-4%) - generate revenue in ETH with every buy/sell after the token hits the bonding curve.</li>
        <li><b>Step 5:</b> Launch your token!</li>
        <p><b>ADDITIONAL NOTES:</b> </p>
        <li><b>Creator Bonus:</b> Earn 0.04 ETH bonus (approx. $100) once bonding curve is reached.</li>
        <li><b>XP Points:</b> Earn XP points for every token you create, with supercharged rewards for tax tokens.</li>
        </ul>
  `,
};
export default data;
