"use client";
import Popup from "@/components/Popup/Popup";
import React, { useState } from "react";
import styles from "./share.module.scss";
import Button from "@/components/Button";
import { Images, LEFT_ARROW_ICON_BLUE } from "@/utils/imageRelativePaths";
import Svg from "react-inlinesvg";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { hidePopUp } from "@/redux/reducers/PopUpsReducer";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { copyUrlToClipboard } from "@/utils/helper";
import userOpenLink from "@/customHooks/userOpenLink";

export default function SharePopUp() {
  const dispatch = useAppDispatch();
  const popUpConfig = useAppSelector((state) => state.PopUps.config);
  const closePopUp = () => {
    dispatch(hidePopUp("Share"));
  };
  const handleReport = async () => {
    closePopUp();
  };
  const SOCIAL_LINKS = [
    { key: "Whatsapp", icon: "/assets/icons/whatspp.svg" },
    { key: "Telegram", icon: "/assets/icons/telegram.svg" },
    { key: "X", icon: "/assets/icons/twitter.svg" },
  ];
  let msgTxt = "Check this out!!";
  let shareURL = window.location.href;
  if (popUpConfig && "text" in popUpConfig) {
    msgTxt = popUpConfig.text;
  }
  if (popUpConfig && "link" in popUpConfig && popUpConfig.link) {
    shareURL = popUpConfig.link;
  }
  const handleSocialLink = (key: string) => {
    let link;
    const shareMsg = msgTxt + " " + shareURL;
    switch (key) {
      case "Whatsapp": {
        link = `https://wa.me/?text=${encodeURIComponent(shareMsg)}`;
        break;
      }
      case "X": {
        link = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMsg)}`;
        break;
      }
      case "Telegram": {
        link = `https://t.me/share/url?text=${encodeURIComponent(shareMsg)}`;
        break;
      }
      default: {
      }
    }
    if (link) {
      window.open(link, "_blank");
    }
  };
  return (
    <Popup show={true} onClose={() => {}}>
      <div className={styles.mainCont}>
        <Svg
          src={Images.iconCross}
          width={14}
          height={14}
          onClick={closePopUp}
          className="absolute cursor-pointer top-[28px] right-[34px]"
        />
        <h2
          className={
            "text-[16px] text-left leading-5 font-semibold text-primary-dark mb-4"
          }
        >
          Share Page
        </h2>
        <div className={"flex my-4 gap-4 items-center"}>
          {SOCIAL_LINKS.map((item) => (
            <div
              className={"flex flex-col gap-2 items-center cursor-pointer"}
              key={item.key}
              onClick={() => handleSocialLink(item.key)}
            >
              <Image alt={item.key} src={item.icon} width={50} height={50} />
              <span className={"text-xs text-primary-dark font-medium"}>
                {item.key}
              </span>
            </div>
          ))}
        </div>
        <div className={styles.inputCont}>
          <input type="text" value={shareURL} />
          <Button onClick={() => copyUrlToClipboard()}>Copy</Button>
        </div>
      </div>
    </Popup>
  );
}
