import { singleItem } from "@/types/global";
import {
  NO_COIN_ICON,
  NO_FOLLOWERS_ICON,
  NO_MEMES_ICON,
  NO_ROCKETS_ICON,
} from "@/utils/imageRelativePaths";

export interface TableDataProps {
  header: singleItem[];
  data: any[];
  actions: string[];
}

export interface ToastProps {
  type: "info" | "success" | "warning" | "error";
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  message: string;
}

export enum DropdownPositionENUM {
  TOP_RIGHT = "top-[-240%] right-[0px]",
  TOP_LEFT = "top-[-240%]",
  BOUTTOM_RIGHT = "right-0 ",
  BOUTTOM_LEFT = "left-[0px]",
}

export enum EmptyTabsImageENUM {
  NO_COIN = `${NO_COIN_ICON}`,
  NO_FOLLOWERS = `${NO_FOLLOWERS_ICON}`,
  NO_ROCKETS = `${NO_ROCKETS_ICON}`,
  NO_MEMES = `${NO_MEMES_ICON}`,
}

export interface paginationConfigType {
  prev?: string;
  next?: string;
  action: "prev" | "next";
}
