"use client";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { hidePopUp } from "@/redux/reducers/PopUpsReducer";
import CreateCoin from "@/components/CreateCoinPopUp";
import HowItWorksPopup from "@/components/HowItWorks";
import ReportPopUp from "@/components/ReportPopup";
import ProfilePopUp from "@/components/ProfilePopUp";
import { useEffect } from "react";
import useWalletStatus from "@/customHooks/useWalletStatus";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import SharePopUp from "@/components/SharePopup";

export default function PopupHandler() {
  const { open } = useWeb3Modal();
  const {
    CreateCoinPopUp,
    Profile,
    HowItWorks,
    TodaysMeme,
    Report,
    Share,
    config,
  } = useAppSelector((state) => state.PopUps);
  const dispatch = useAppDispatch();
  const { isConnected, notifyUser } = useWalletStatus(true);
  useEffect(() => {
    // show error if wallet is not connected and close popup\
    if (CreateCoinPopUp && !isConnected) {
      open();
      dispatch(hidePopUp("CreateCoinPopUp"));
    }
  }, [CreateCoinPopUp]);

  return (
    <>
      {CreateCoinPopUp && isConnected ? (
        <CreateCoin
          onPopupClose={() => dispatch(hidePopUp("CreateCoinPopUp"))}
        />
      ) : (
        ""
      )}
      {HowItWorks ? <HowItWorksPopup /> : ""}
      {Report ? <ReportPopUp /> : ""}
      {Profile ? <ProfilePopUp /> : ""}
      {Share ? <SharePopUp /> : ""}
    </>
  );
}
