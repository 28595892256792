"use client";
import Image from "next/image";
import { CHEVRON_ICON_DOWN, Images } from "@/utils/imageRelativePaths";
import Dropdown from "../Dropdown";
import { useAccount, useBalance } from "wagmi";
import { useRouter } from "next/navigation";
import {
  formatNumberWithLetters,
  getImageUrl,
  maskWalletAddress,
} from "@/utils/helper";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { logout } from "@/redux/reducers/AuthReducer";
import { ethers } from "ethers";

const WalletCart = () => {
  const account = useAccount();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.Auth.user);
  const balanceData = useBalance({
    address: account?.address,
  });
  let userBalance = "0";
  if (balanceData?.data?.value) {
    userBalance = ethers.utils.formatEther(balanceData.data.value).toString();
  }
  const handleDisconnect = () => {
    if (account.isConnected && account?.connector?.disconnect) {
      account.connector.disconnect();
      dispatch(logout());
    }
  };

  return (
    <Dropdown
      parentContainer={
        <>
          <div className="px-4 py-2 cursor-pointer flex bg-white lg:rounded-[50px] rounded-[6px] items-center justify-start  shadow-[0px_2px_8px_0px_#272C3C0A]">
            <Image
              src={
                getImageUrl(user?.profilePic) ||
                (Images.FOMO_CLICK_LOGO as string)
              }
              alt="img"
              className={"h-[31px] rounded-[50%] object-contain"}
              height={31}
              width={31}
            />
            <span className=" ml-[12px] text-sm text-primary-dark leading-[16px] font-normal hidden lg:block">
              {`${maskWalletAddress(account.address)} (${formatNumberWithLetters(userBalance, 4, true)} ETH)`}
            </span>
            <Image
              src={CHEVRON_ICON_DOWN}
              alt="icon"
              width={12}
              height={6}
              className="ml-[10px] cursor-pointer"
            />
          </div>
        </>
      }
      className="right-0"
    >
      <div className="flex flex-col h-max">
        <span
          onClick={() => router.push("/profile/" + user?._id)}
          className="px-[18px] hover:bg-gray-100 pr-[50px] text-primary-dark py-[14px] text-[12px] leading-[14px] font-normal cursor-pointer"
        >
          My Profile
        </span>
        <hr />
        <span
          onClick={handleDisconnect}
          className="px-[18px] hover:bg-gray-100 pr-[50px] hover:bg-gray-10 text-primary-dark py-[14px] text-[12px] leading-[14px] font-normal cursor-pointer"
        >
          Disconnect Wallet
        </span>
      </div>
    </Dropdown>
  );
};

export default WalletCart;
