import React, { ChangeEvent } from "react";
import styles from "./checkbox.module.scss";

const RadioButton = ({
  checked,
  onChange,
  label,
}: {
  checked: boolean;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  label: string;
}) => {
  return (
    <label className={styles["custom-checkbox"]}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={styles["checkmark"]}></span>
      <span className={"text-xs md:text-base"}>{label}</span>
    </label>
  );
};

export default RadioButton;
