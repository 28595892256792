"use client";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { login, logout } from "@/redux/reducers/AuthReducer";
import { resetError } from "@/redux/reducers/ErrorReducer";
import Loading from "@/components/Loading";
import { getMyDetails } from "@/network/auth";
import useSocket from "@/customHooks/useSocket";
import { ERRORS } from "@/utils/constants";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { setConfig } from "@/redux/reducers/ConfigReducer";
import { configResponseType } from "@/types/global";

export default function AuthWrapper({
  children,
  config,
}: {
  children: React.ReactNode;
  config?: configResponseType;
}) {
  const socket = useSocket();
  const { open } = useWeb3Modal();
  const [loading, setLoading] = useState<boolean>(true);
  const token = useAppSelector((state) => state.Auth.token);
  const error = useAppSelector((state) => state.Error.error);
  const dispatch = useAppDispatch();
  const siteKey = useAppSelector((state) => state.Config.SITE_KEY);

  // set application color based on  site
  useEffect(() => {
    const rootElement: HTMLElement | null = document.documentElement;
    if (rootElement) {
      rootElement.style.setProperty(
        "--primary",
        siteKey == "ceres" ? "#25a082" : "#5280e9",
      );
    }
  }, [siteKey]);
  useEffect(() => {
    const handleLogin = async () => {
      if (token) {
        const user = await getMyDetails();
        if (user && user.address) {
          dispatch(login({ token, user }));
          setLoading(false);
          return;
        }
      }
      dispatch(logout());
      setLoading(false);
    };
    handleLogin();
  }, [token]);

  // handle errors
  useEffect(() => {
    if (error && error === ERRORS.UNAUTHORIZED) {
      dispatch(logout());
      dispatch(resetError());
      open();
    }
  }, [error]);

  // set config in Redux
  useEffect(() => {
    dispatch(setConfig(config));
  }, [config]);
  return (
    <>
      {loading ? (
        <div
          className={"h-screen w-screen bg-[#F6F7F8] grid place-items-center"}
        >
          <Loading size={"lg"} />{" "}
        </div>
      ) : (
        children
      )}
    </>
  );
}
