"use client";
import { ToastContainer } from "react-toastify";

const contextClass = {
  success: "bg-[#EDF7ED] text-[#1e4620] ",
  error: "bg-[#FCEDEC] text-[#5f2120]",
  info: "bg-[#E5F5FD] text-[#014361]",
  warning: "bg-orange-400 text-[#663c00]",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};
export default function Toast() {
  return (
    <ToastContainer
      toastClassName={(context) =>
        contextClass[context?.type || "default"] +
        " relative mt-2 flex p-1 min-h-16 rounded-md justify-between overflow-hidden cursor-pointer"
      }
      bodyClassName={() => "flex items-center text-sm font-med block p-3"}
      hideProgressBar={true}
      autoClose={5000}
    />
  );
}
