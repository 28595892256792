import axios from "@/utils/axios";
import { AxiosError, tokenType } from "@/types/global";
import { getErrorMessage } from "@/utils/helper";
import { toast } from "react-toastify";

interface reportReasonType {
  entityType: string;
  entityId: string;
  reason?: string;
  description: string;
}

export async function reportPage(data: reportReasonType) {
  try {
    const reqBody = data;
    reqBody.reason = "other";
    const response = await axios.post("/reports", reqBody);
    if (response.status === 201) {
      toast.success("Reported Successfully", {
        position: "top-right",
      });
    }
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}

export async function getTokenDetails(mint: string) {
  try {
    const response = await axios.get("/tokens/" + mint);
    return response.data;
  } catch (e) {
    const errorMsg = getErrorMessage(e as AxiosError);
    toast.error(errorMsg, {
      position: "top-right",
    });
  }
}
